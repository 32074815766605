import getNav from "./getNav";
// 获取链接
function getLink(navigationData: Nav[], path: string, defaultLink = '/') {
  const nav = getNav(navigationData, path);
  if (!nav) return defaultLink;

  const subNav = nav.navigationList?.[0];
  if (subNav) {
    return subNav?.link !== "/index" ? `${nav.link}${subNav.link}` : nav.link || defaultLink;
  }
  return nav.link || defaultLink;
}
// 获取导航或底部链接
export default function getFootLink(navigationData: Nav[]) {
  const activityLink = getLink(navigationData, "/list/activity");
  const newsLink = getLink(navigationData, "/list/news");
  const memberLink = getLink(navigationData, "/member");
  const databaseLink = getLink(navigationData, "/database");
  const buildingLink = getLink(navigationData, "/about");

  return { activityLink, newsLink, memberLink, databaseLink, buildingLink };
}