import Image, { ImageLoaderProps, ImageProps } from "next/image";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

import defaultImage from "@/public/assets/images/defaultImage.png";

const NextImage = ({ src = defaultImage, alt = '', ...props }: ImageProps) => {

  function imageLoader({ src, width, quality }: ImageLoaderProps) {
    const isSrc = src.startsWith('//')
    const isHttp = !/^(http|https):\/\//.test(src)
    if (isSrc && isHttp) {
      const s = src.replace('//', '/');
      return `${s}?w=${width}&q=${quality || 75}`
    }
    return `${isHttp ? publicRuntimeConfig.BASE_PATH : ''}${src}?w=${width}&q=${quality || 75}`;
  }
  return <Image
    style={{ verticalAlign: "middle" }}
    loader={imageLoader}
    {...props}
    alt={alt}
    src={src}
  />
};
export default NextImage
