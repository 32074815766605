export default function getNav(navs: Nav[], pathname: string = "") {
  // 如果 pathname 为空，返回第一个 nav
  if (!pathname) {
    return null;
  }
  // 如果 pathname 不为空，查看pathname最后一个字符是否为 / ，如果是，去掉最后一个字符
  if (pathname !== "/" && pathname.endsWith("/")) {
    pathname = pathname.slice(0, -1);
  }
  const nav = navs.find((nav) => nav.link.startsWith(pathname ?? ""));
  if (nav) {
    return nav;
  }
  return null;
}
